import { ARABIC } from "../../../../public/Constants/EnumConstants";
export const SELECT_TEXT = "Select";
export const ECOM_SELECT_TEXT = "CHOOSE YOUR SIZE";
const CHOOSE_TEXT = "Please select";
const CHOOSE_TEXT_AR = "الرجاء التحديد";
export const getSelectText = (locale) => {
    return locale === ARABIC ? CHOOSE_TEXT_AR : CHOOSE_TEXT;
};
export const SHORTEN_WORDS = (value) =>
    value?.length > 10 ? value?.slice(0, 10) + "..." : value;
