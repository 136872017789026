import { toast } from "react-toastify";
import { createClient } from "../../prismicio";
import {
    ARABIC,
    ENGLISH,
    LEFT,
    RIGHT,
    TEXT_LEFT,
    TEXT_RIGHT,
} from "../../public/Constants/EnumConstants";
import { BACKEND_ENDPOINTS } from "../../public/Constants/endpoints";
import * as Sentry from "@sentry/nextjs";

const getAlignment = (locale) => {
    return locale === ARABIC ? RIGHT : LEFT;
};
const getAlignmentClass = (locale) => {
    return locale === ENGLISH ? TEXT_LEFT : TEXT_RIGHT;
};
const splitSliceIntoTwoParts = (array) => {
    const first_slice = array?.items?.slice(0, 2);
    const second_slice = array?.items?.slice(2);
    return { first_slice, second_slice };
};
const formatDate = (inputDate) => {
    let date, month, year;

    date = inputDate?.getDate();
    month = inputDate?.getMonth() + 1;
    year = inputDate?.getFullYear();

    date = date?.toString().padStart(2, "0");

    month = month?.toString().padStart(2, "0");

    return `${date}/${month}/${year}`;
};

const formatDateYMD = (inputDate) => {
    if (inputDate === null) return;
    let date, month, year;
    date = inputDate?.getDate();
    month = inputDate?.getMonth() + 1;
    year = inputDate?.getFullYear()?.toString();

    date = date?.toString().padStart(2, "0");

    month = month?.toString().padStart(2, "0");

    return `${year}-${month}-${date}`;
};

const mmToFeetInches = (mm) =>
    `${Math.floor(mm / 25.4 / 12)} ft ${Math.ceil((mm / 25.4) % 12)} in`;

const gramsToKgs = (grams) => `${Math.ceil(grams / 1000)} kg`;

export {
    getAlignment,
    getAlignmentClass,
    splitSliceIntoTwoParts,
    formatDate,
    formatDateYMD,
    mmToFeetInches,
    gramsToKgs,
};

export const fetchLandingPageProducts = async () => {
    let BASE_URL = `${process.env.BACKEND_URL}${BACKEND_ENDPOINTS?.ECOM_PRODUCTS}`; // Corrected URL format
    let URL = BASE_URL;
    try {
        const response = await fetch(URL, {
            method: "GET",
            cache: "no-store",
        });
        if (response.ok) {
            const data = await response.json();
            // Process your data here
            return data;
        } else {
            console.error("Failed to fetch data:", response.status);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return error;
    }
};
export const fetchProduct = async (id) => {
    let BASE_URL = `${process.env.BACKEND_URL}${BACKEND_ENDPOINTS?.PRODUCT}${id}`; // Corrected URL format
    let URL = BASE_URL;
    try {
        const response = await fetch(URL, {
            method: "GET",
            cache: "no-store",
        });
        if (response.ok) {
            const data = await response.json();
            // Process your data here
            return data;
        } else {
            console.error("Failed to fetch data:", response.status);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return error;
    }
};
export const generateSignature = (publicId, apiSecret) => {
    const timestamp = Math.floor(Date.now() / 1000);
    const signaturePayload = `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`;
    const signature = crypto
        .createHash("sha1")
        .update(signaturePayload)
        .digest("hex");
    return { signature, timestamp };
};

export const fetchClientSideData = async (pageType) => {
    const client = createClient();
    try {
        const response = await client.getSingle(pageType);
        return response?.data;
    } catch (error) {
        if (typeof window !== "undefined") {
            Sentry.captureException(error);
        }
        console.error("Error fetching client-side data:", error);
        return error;
    }
};

export const fetchPrismicDataById = async (pageType, id) => {
    const client = createClient();
    try {
        const page = await client?.getByUID(pageType, id);
        return page;
    } catch (error) {
        if (typeof window !== "undefined") {
            Sentry.captureException(error);
        }
        console.error("Error fetching client-side data:", error);
        return error;
    }
};

export const fetchAllByType = async (type) => {
    const client = createClient();
    try {
        const pages = await client?.getAllByType(type);
        return pages;
    } catch (error) {
        if (typeof window !== "undefined") {
            Sentry.captureException(error);
        }
        console.error("Error fetching client-side data:", error);
        return error;
    }
};

export const customNoticError = (error, message, attribute) => {
    try {
        if (typeof window !== "undefined") {
            Sentry.captureException(error);
        }
    } catch (err) {
        console.log(err);
    }
};

export const checkInvalidDatesForBox = (data) => {
    let { selectedDate, setDate, fieldName } = data;

    // list of invalid dates
    let invalidDates = ["2023-12-25", "2024-01-01"];
    const NOT_AVAILABLE_TEXT =
        "We are not available on the selected date for any services. Sorry for any inconvenience.";

    // check if the selected date is valid
    let dateInvalid = invalidDates?.find(
        (date) =>
            selectedDate?.getDate() === new Date(date)?.getDate() &&
            selectedDate?.getMonth() === new Date(date)?.getMonth()
    )
        ? true
        : false;

    // if the selected date is invalid then set the selected date to 1 day earlier
    if (dateInvalid) {
        let newDate = new Date(selectedDate);
        newDate?.setDate(newDate?.getDate() - 1);
        setDate(fieldName, newDate);
        toast(NOT_AVAILABLE_TEXT, { type: "error" });
        return;
    }
    return;
};

export const isIterable = (obj) => {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    // returns the status of the object if it's iterable
    // returns true for string as it is iterable
    return typeof obj[Symbol.iterator] === "function";
};
