export const INPUT_CONTAINER = "text-sm items-center";
export const INPUT_FIELD =
    "border border-New_Primary_Default focus:outline-none rounded w-[100%] h-[41px]  ";
export const INPUT_FIELD_ERROR =
    "border border-Error_Default focus:outline-none rounded w-[100%] h-[41px]";

export const LABEL =
    "font-normal text-[13px] md:text-base text-LIGHT_BLACK pb-1";

export const ERROR_MESSAGE_TEXT = "Empty Fields not Allowed";
