import React from "react";
import { OPTION_CLASS_NAME, NEW_SELECTED_OPTION_CLASS_NAME } from "./styles";
import Image from "next/image";

const NewDropdownOption = ({
    field,
    value,
    image,
    setSelectedValue,
    selectedValue,
    setShowDropdown,
    setValue,
    showIcons,
}) => {
    const compareValue =
        value?.length > 10 ? value?.slice(0, 10) + "..." : value;
    const optionClass =
        selectedValue === compareValue
            ? NEW_SELECTED_OPTION_CLASS_NAME
            : OPTION_CLASS_NAME;
    return (
        <div
            className={optionClass}
            tabIndex={0}
            onClick={() => {
                setValue(field, value);
                setSelectedValue(
                    value?.length > 10 ? value?.slice(0, 10) + "..." : value
                );
                setShowDropdown(false);
            }}
        >
            <div>{value}</div>
            {showIcons && (
                <Image alt={"icon"} src={image} width={30} height={30} />
            )}
        </div>
    );
};

export default NewDropdownOption;
