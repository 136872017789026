export const RELATIVE_CLASS = " relative";
export const INPUT_CONTAINER = "flex flex-col justify-center";
export const LABEL =
    "font-normal text-[13px] md:text-base text-LIGHT_BLACK pb-1";
export const FIELD_TEXT_CLASS = "whitespace-nowrap";
export const DROPDOWN_CARROT_CLASS = "absolute right-2";
export const CARROT_CLASS = "transition-all delay-50 duration-300";
export const CARROT_ROTATE = " rotate-180";
export const DROPDOWN_OPTIONS_CONTAINER =
    "relative z-20 drop-shadow-6xl transition-all delay-50 duration-300";
export const DROPDOWN_OPTIONS_OPEN = " h-full opacity-100";
export const DROPDOWN_OPTIONS_CLOSE = " h-0 opacity-0";
export const DROPDOWN_OPTIONS_INNER_CONTAINER =
    "max-h-60 overflow-auto absolute bg-Primary_Background w-full border border-FIELD_PINK flex flex-col ";
export const NEW_DROPDOWN_OPTIONS_INNER_CONTAINER =
    "max-h-60 overflow-auto absolute bg-Primary_Background w-full border border-New_Primary_Default flex flex-col ";
export const OPTION_CLASS_NAME =
    "cursor-pointer hover:bg-Primary_Default flex items-center justify-between py-1 px-4 border-b";
export const SELECTED_OPTION_CLASS_NAME =
    "cursor-pointer bg-Primary_Default text-white flex items-center justify-between py-1 px-4 border-b";
export const BLACK_SELECTED_OPTION_CLASS_NAME =
    "cursor-pointer bg-New_Primary_Default text-white flex items-center justify-between py-1 px-4 border-b";
export const GROUP_HEADING_CONTAINER = "pl-2 mt-h1";

export const NEW_SELECTED_OPTION_CLASS_NAME =
    "cursor-pointer bg-New_Primary_Default text-white flex items-center justify-between py-1 px-4 border-b";
export const NEW_INPUT_FIELD_CLASS =
    "bg-transparent border border-New_Primary_Default focus:outline-none rounded w-[100%] h-[41px] px-4 ";
