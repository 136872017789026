import React from "react";
import {
    OPTION_CLASS_NAME,
    SELECTED_OPTION_CLASS_NAME,
    BLACK_SELECTED_OPTION_CLASS_NAME,
} from "./styles";
import Image from "next/image";

const CustomDropdownOption = ({
    field,
    value,
    image,
    setSelectedValue,
    selectedValue,
    setShowDropdown,
    setButtonDisabled,
    setValue,
    showIcons,
    varient,
}) => {
    const compareValue =
        value?.length > 10 ? value?.slice(0, 10) + "..." : value;
    const optionClass =
        selectedValue === compareValue
            ? varient === "black"
                ? BLACK_SELECTED_OPTION_CLASS_NAME
                : SELECTED_OPTION_CLASS_NAME
            : OPTION_CLASS_NAME;
    return (
        <div
            className={optionClass}
            tabIndex={0}
            onClick={() => {
                setButtonDisabled && setButtonDisabled(false);
                setValue(field, value);
                setSelectedValue(
                    value?.length > 10 ? value?.slice(0, 10) + "..." : value
                );
                setShowDropdown(false);
            }}
        >
            <div>{value}</div>
            {showIcons && (
                <Image alt="dropdown icon" src={image} width={30} height={30} />
            )}
        </div>
    );
};

export default CustomDropdownOption;
